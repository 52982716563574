import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Footer from '../components/layout/footer';
import Link from '../components/link/link';
import NextPage from '../components/navigation/next/next-container';
import SEO from '../components/seo/seo';

import './index.css';

const IndexPage = () => (
  <div>
    <SEO />
    <main className="cover">
      <div className="cover__inner">
        <StaticImage
          alt="The sockpicker carrying a sock"
          aspectRatio={3535 / 4229}
          className="cover__image"
          height={750}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../images/sockpicker.png"
        />
        <header>
          <h1>The Sockpicker</h1>
          <address>
            <div className="cover__author">By Margaret Randall</div>
            <div className="cover__illustrator">Illustrated by Sarah Knight</div>
          </address>
          <StaticImage
            alt="A row of socks"
            className="cover__socks"
            height={50}
            layout="constrained"
            loading="eager"
            placeholder="tracedSVG"
            src="../images/socks.png"
            width={332}
          />
          <p className="cover__text">
            <span>A story written and illustrated with love,</span>
            <span>for all the little feet in our lives.</span>
          </p>
          <Link
            bg="#403075"
            direction="left"
            swipe
            to="/page-1"
          >
            Start reading
          </Link>
          <NextPage
            hidden
            to="/page-1"
          />
        </header>
      </div>
      <Footer links={['author', 'contact', 'shop']} />
    </main>
  </div>
);

export default IndexPage;
